/* eslint-disable class-methods-use-this */
/* eslint-disable max-lines */
/* eslint camelcase: ["error", {properties: "never"}] */
/* eslint-disable multiline-ternary */
/* eslint-disable camelcase */
import * as AppStateActions from '../../../features/modules/state/app-state.actions';
import * as _ from 'lodash';
import { debounce } from 'lodash';
import * as fns from 'date-fns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

import * as i from '../../../features/modules/state/interfaces';
import { Product, Station, Supplier } from '../../../features/modules/state/interfaces';
import { Api, OrderInput } from '../../../api/src/Api';
import {
    calculateTicketZoneAndArea,
    calculateViewTicketDiscount,
    calculateViewTicketDuration,
    calculateViewTicketZone
} from '../../utils/resolve-ticket-data';
import { AppState } from '../../../features/modules/state/app.state';
import { RefreshTokenService } from '../../services/refresh-token.service';
import { environment } from '../../../../environments/environment';
import { nipValidator } from '../../utils/validators/nip.validator';
import { TranslationManagerService } from '../../services/translation-manager.service';
import { isDefaultTicketCarrier } from '../../utils/ticket-carriers';

interface ShoppingListItem {
    count: number;
    ticketCarrierId: string;

    type: 'product' | 'ticket';

    ticketId: string;
    productId: string;
    activatedAt: any;
    activeFromNow: boolean;
    ticket?: any;
    product?: any;
    ticketZone?: any;
    duration?: any;
    activeFrom?: any;
}

interface LackOfProductsError {
    amount: boolean;
    items: {
        amount: number;
        productId: string;
        sale: boolean;
    }[];
}

const transportAuthority = `{{selectedTransportAuthority}}`;

const baseRoutes = [
    {
        name: `navigation.tickets`,
        link: `${transportAuthority}/bilety`,
    },
    {
        name: `navigation.help`,
        link: `faq`,
    },
    {
        name: `navigation.statue`,
        link: `regulamin`,
    },
    {
        name: `navigation.contact`,
        link: `kontakt`,
    },
];

@Component({
    selector: `app-layout`,
    templateUrl: `layout.component.html`,
    styleUrls: [`layout.component.scss`],
})
export class LayoutComponent {
    @Select(AppState.selectedTransportAuthority)
    selectedTransportAuthority$!: Observable<any>;

    @Select(AppState.transportAuthorities)
    transportAuthorities$!: Observable<any[]>;

    @Select(AppState.products)
    products$!: Observable<Product[]>;

    @Select(AppState.policies)
    policies$!: Observable<any[]>;

    @Select(AppState.tickets)
    tickets$!: Observable<any[]>;

    @Select(AppState.showHeaderTransportAuthoritiesDropdown)
    showHeaderTransportAuthoritiesDropdown$!: Observable<boolean>;

    @Select(AppState.showChooseTransportAuthorityDialog)
    showChooseTransportAuthorityDialog$!: Observable<boolean>;

    @Select(AppState.cartHidden)
    cartHidden$!: Observable<any>;

    @Select(AppState.userData)
    userData$!: Observable<any>;

    @Select(AppState.suppliers)
    suppliers$!: Observable<Supplier[]>;

    @Select(AppState.pickupPoints)
    pickupPoints$!: Observable<Station[]>;

    readonly stations$ = this.pickupPoints$.pipe(
        tap(([firstPickupPoint]) => this.selectedPickupPoint = firstPickupPoint.name),
    );

    private fontSize = 100;
    private html!: HTMLElement;

    readonly isDefaultTicketCarrier = isDefaultTicketCarrier;

    logo!: string;
    buyButtonClicked = false;

    inputNumberValue = 2;
    something = [
        {
            value: 2,
            text: `two`,
        },
        {
            value: 1,
            text: `one`,
        },
    ];

    readonly userText$ = new BehaviorSubject({
        name: ``,
        link: ``,
    });

    languages = [
        {
            src: `/assets/icons/005-poland.svg`,
            value: `PL`,
        },
    ];

    readonly navigation$ = combineLatest([
        this.selectedTransportAuthority$,
        this.userText$,
    ]).pipe(
        map(
            ([selectedAuthority, userText]) => [
                ...baseRoutes.map((route) => ({
                    ...route,
                    link: route.link.replace(transportAuthority, selectedAuthority?.slug),
                })),
                userText,
            ],
        ),
        shareReplay(1),
    );

    customerInfoLayoutFormGroup = new FormGroup({
        companyName: new FormControl(),
        firstName: new FormControl(``, [Validators.required]),
        lastName: new FormControl(``, [Validators.required]),
        line1: new FormControl(``, [Validators.required]),
        line2: new FormControl(),
        postalCode: new FormControl(``, [Validators.required]),
        city: new FormControl(``, [Validators.required]),
        dateOfBirth: new FormControl(``, [Validators.required]),
        phoneNumber: new FormControl(),
        vatNumber: new FormControl(``),
    });

    selectedLanguage = this.languages[0];

    // misc
    userData!: i.UserData | undefined;
    displayPaymentRedirectDialog = false;
    headerMargin!: any;
    initSelectedCustomerInfo: any;
    initCustomerInfoFormGroup: any;
    checkBoxValue!: boolean;
    approvedCustomerInfo = false;
    nameTextValue = ``;
    ms = 1000 * 60;

    // transport authorities
    transportAuthorities: i.TransportAuthority[] = [];
    selectedTransportAuthority!: any;

    // customer info
    displayCustomerInfoLayoutDialog = false;
    selectedCustomerInfo!: any;
    customerInfos!: any[];
    mappedCustomerInfos!: any[];
    currentInfos!: any[];

    // subscriptions
    userSubscription!: Subscription;
    userDataSubscription!: Subscription;
    selectedTransportAuthoritySubscription!: Subscription;
    transportAuthoritiesSubscription!: Subscription;
    cartSubscription!: Subscription;

    // shopping list & cart
    displayCartDialog = false;
    cartHidden = true;
    shoppingList!: any;
    ticketCarriers: any[] = [];
    tickets!: i.Ticket[];
    itemsPrice: any;
    onCartModified$ = new Subject<any>();
    newShoppingListItems: ShoppingListItem[] = [];
    shoppingListItems: ShoppingListItem[] = [];

    // payment methods
    displayPaymentMethodDialog = false;
    selectedPaymentMethod: any;
    paymentMethods!: any[];

    availableProducts!: Product[];

    selectedPickupPoint?: string;
    supplyMethod?: Supplier;

    readonly invoiceForm = new FormGroup({
        invoice: new FormControl(false),
    });

    constructor(
        readonly translationService: TranslationManagerService,
        private readonly router: Router,
        private readonly store: Store,
        private readonly apiService: Api,
        private readonly messageService: MessageService,
        private readonly confirmationService: ConfirmationService,
        private readonly refreshTokenService: RefreshTokenService,
        private readonly translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.html = document.querySelector(`html`)!;

        this.setCustomLogo();

        this.registerOnCartChangedListener();

        this.headerMargin = `0px`;

        this.products$.subscribe((products) => {
            this.availableProducts = products;

            this.tryToRestoreShoppingCart();
        });

        this.selectedTransportAuthoritySubscription = this.selectedTransportAuthority$.subscribe(
            (selectedTransportAuthority) => {
                this.selectedTransportAuthority = selectedTransportAuthority;

                if (!selectedTransportAuthority) {
                    return;
                }

                this.store.dispatch(new AppStateActions.GetPolicies());
                this.store.dispatch(new AppStateActions.GetProducts());
                this.store.dispatch(new AppStateActions.GetFaqs());
                this.store.dispatch(new AppStateActions.GetSuppliers());
                this.store.dispatch(new AppStateActions.GetStations());
                this.store.dispatch(new AppStateActions.GetPickupPoints());

                this.tickets = this.selectedTransportAuthority.tickets;

                this.tryToRestoreShoppingCart();
            },
        );

        const { token } = this;

        if (token) {
            interface IToken {
                name: string;
                exp: number;
            }

            const decodedToken = jwt_decode<IToken>(token);

            if (decodedToken.exp * 1000 < Date.now()) {
                void this.refreshTokenService
                    .refreshUser()
                    .then(() => this.store.dispatch(new AppStateActions.GetUser()));
            }
        }

        this.store.dispatch(new AppStateActions.Get()).subscribe(() => {
            this.store.dispatch(new AppStateActions.Deduce());
        });

        this.transportAuthoritiesSubscription = this.transportAuthorities$.subscribe((transportAuthorities) => {
            if (transportAuthorities) {
                this.transportAuthorities = transportAuthorities;
            }
        });

        this.cartSubscription = this.cartHidden$.subscribe((cartHidden: boolean) => {
            this.cartHidden = cartHidden;
            if (this.cartHidden) {
                this.displayCartDialog = false;
                return;
            }
            this.displayCartDialog = true;
        });

        this.changeCartMargin();
        this.deduceUserText();
        void this.subscribeUserData();
    }

    ngAfterViewInit() {
        this.changeCartMargin();

        type ResizeListener = (this: Window, ev: WindowEventMap['resize']) => void;
        window.addEventListener(`resize`, debounce(() => this.changeCartMargin(), 500) as ResizeListener);
    }

    setCustomLogo() {
        this.logo = environment.instanceConfiguration.logo;
    }

    registerOnCartChangedListener() {
        this.onCartModified$
            .pipe(
                debounceTime(750),
                distinctUntilChanged((prevList, nextList) => {
                    return JSON.stringify(prevList) === JSON.stringify(nextList);
                }),
            )
            .subscribe((newShoppingListItems: ShoppingListItem[]) => {
                const toUpdateShoppingList = newShoppingListItems.map((listItem) => {
                    const { ticket, ticketZone, duration, activeFrom, ...item } = listItem;
                    return item;
                });

                this.calculateItemsPrice(newShoppingListItems);

                if (this.userData?.id) {
                    void this.apiService.users.usersControllerUpdateShoppingList(
                        this.userData.id,
                        {
                            transportAuthorityId: this.selectedTransportAuthority.id,
                            items: this.shoppingListToDatabaseFormat(toUpdateShoppingList),
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                            },
                        },
                    );
                }
            });
    }

    changeCartMargin() {
        if (window.innerWidth > 1000) {
            this.headerMargin = `15%`;
        } else {
            this.headerMargin = `0`;
        }
    }

    deduceUserText() {
        this.userSubscription = this.userData$.subscribe((userData) => {
            this.userText$.next({
                name:  userData && !userData.guest ? `navigation.myAccount` : userData && userData.guest ? 'navigation.login' : `navigation.login`,
                link: userData && !userData.guest ? `uzytkownik/profil` : userData && userData.guest ? `uzytkownik/logowanie`   : `uzytkownik/logowanie`,
            });
        });
    }

    ngOnDestroy() {
        this.cartSubscription.unsubscribe();
        this.userSubscription.unsubscribe();
        this.userDataSubscription.unsubscribe();
        this.selectedTransportAuthoritySubscription.unsubscribe();
        this.transportAuthoritiesSubscription.unsubscribe();
    }

    async subscribeUserData() {
        this.store.dispatch(new AppStateActions.GetUser());
        this.userDataSubscription = this.userData$.subscribe((userData: i.UserData | undefined) => {
            if (!userData) {
                this.newShoppingListItems = [];
                this.customerInfos = [];
                this.ticketCarriers = [];
                this.currentInfos = [];
                this.selectedCustomerInfo = undefined;
                this.selectedPaymentMethod = undefined;
                this.customerInfoLayoutFormGroup.reset();
                return;
            }

            this.userData = userData;

            if (this.userData.customerInfos) {
                this.customerInfos = this.userData.customerInfos;
            }

            this.mappedCustomerInfos = this.customerInfos.map((customerInfo: i.CustomerInfo | undefined) => {
                return {
                    label: `${customerInfo?.firstName} ${customerInfo?.lastName}`,
                    customerInfo: customerInfo ?? ``,
                };
            });

            // const newInfo = {
            //     label: this.translateService.instant(`cart.customer.newData`),
            //     customerInfo: ``,
            // };
            //
            // this.mappedCustomerInfos.push(newInfo);

            this.tryToRestoreShoppingCart();
        });
    }

    public tryToRestoreShoppingCart() {
        // resolved data is loaded asynchronously that results in race
        const requiredDataToRestoreCart = [
            this.userData,
            this.selectedTransportAuthority,
            this.tickets,
            this.availableProducts,
        ];

        if (requiredDataToRestoreCart.some((data) => data == null)) {
            return;
        }

        this.shoppingList = this.userData!.shoppingLists?.find((shoppingList: any) =>
            shoppingList.transportAuthorityId === this.selectedTransportAuthority?.id);

        if (!this.shoppingList) {
            return;
        }

        const availableIds = [
            ...this.tickets,
            ...this.availableProducts,
        ].map(({ id }) => id);

        const filteredItems = this.shoppingList.items.filter((item: any) =>
            availableIds.some((id) => id === (item.ticketId || item.productId)));

        this.getVisibleTicketCarriers(filteredItems);

        this.shoppingListItems = filteredItems.map((item: any) => {
            const type = item.ticketId ? `ticket` : `product`;

            const rest = type === `ticket`
                ? {
                    ticket: this.tickets.find(({ id }) => id === item.ticketId),
                }
                : {
                    product: this.availableProducts.find(({ id }) => id === item.productId),
                };

            return {
                ...item,
                type,
                ...rest,
            };
        });

        if (this.shoppingList.items.length !== this.shoppingListItems && this.userData!.id) {
            void this.apiService.users.usersControllerUpdateShoppingList(
                this.userData!.id,
                {
                    transportAuthorityId: this.selectedTransportAuthority.id,
                    items: this.shoppingListToDatabaseFormat(this.shoppingListItems),
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                },
            );
        }


        this.newShoppingListItems = this.shoppingListFromDatabaseFormat(_.cloneDeep(this.shoppingListItems));

        this.onCartModified$.next(this.newShoppingListItems);
    }

    get isAnyProductInCart(): boolean {
        return this.shoppingListItems.some(({ type }) => type === `product`);
    }

    async onSelectedTransportAuthority(event: any) {
        const { slug } = event.value;

        this.newShoppingListItems = [];
        this.ticketCarriers = [];
        this.selectedPaymentMethod = undefined;

        this.store.dispatch(new AppStateActions.Select(slug));

        if (this.userData) {
            this.store.dispatch(new AppStateActions.GetUser());
        }

        const urlParts = this.router.url.split(`/`);
        urlParts[1] = slug;
        const newUrl = urlParts.join(`/`);
        void this.router.navigate([newUrl]);
    }

    async onDialogDropdownSelectedTransportAuthority(event: any) {
        const { slug } = event.value;
        this.store.dispatch(new AppStateActions.Select(slug));
        this.store.dispatch(new AppStateActions.ShowChooseDialog(false));
        void this.router.navigate([`/${slug}`]);
    }

    onCartModifiedCount(item: ShoppingListItem, value: number) {
        item.count = value;

        this.onCartModified$.next(this.shoppingListToDatabaseFormat(this.newShoppingListItems));
    }

    changeCustomer(empty: boolean) {
        this.checkBoxValue = false;
        this.nameTextValue = ``;

        if (empty) {
            this.initCustomerInfoFormGroup = this.customerInfoLayoutFormGroup.value;
            this.initSelectedCustomerInfo = this.selectedCustomerInfo;
            return;
        }

        if (this.selectedCustomerInfo.label === `Nowe dane` && this.selectedCustomerInfo.customerInfo === ``) {
            this.customerInfoLayoutFormGroup.reset();
            return;
        }

        this.currentInfos = this.customerInfos.filter((el) => {
            return el.id === this.selectedCustomerInfo?.customerInfo.id;
        });

        if (this.currentInfos.length > 0) {
            this.customerInfoLayoutFormGroup.setValue({
                companyName: this.currentInfos[0].companyName,
                firstName: this.currentInfos[0].firstName,
                lastName: this.currentInfos[0].lastName,
                line1: this.currentInfos[0].line1,
                line2: this.currentInfos[0].line2,
                postalCode: this.currentInfos[0].postalCode,
                city: this.currentInfos[0].city,
                phoneNumber: this.currentInfos[0].phoneNumber,
                dateOfBirth: this.currentInfos[0].dateOfBirth,
                vatNumber: this.currentInfos[0].vatNumber,
            });
        }
    }

    onCloseCustomerInfoDialog() {
        this.nameTextValue = ``;
        this.checkBoxValue = false;
        if (!this.approvedCustomerInfo) {
            this.customerInfoLayoutFormGroup.setValue(this.initCustomerInfoFormGroup);
            this.selectedCustomerInfo = this.initSelectedCustomerInfo;
        }
        this.approvedCustomerInfo = false;
    }

    async clickChooseCustomerInfo() {
        this.currentInfos = [this.customerInfoLayoutFormGroup.value];
        if (this.checkBoxValue) {
            const customerInfoWithName = {
                ...this.customerInfoLayoutFormGroup.value,
                name: this.nameTextValue,
            };

            if (this.userData?.id) {
                await this.apiService.users
                    .usersControllerCreateCustomerInfo(this.userData.id, customerInfoWithName, {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    })
                    .catch(() => {
                        this.messageService.add({
                            severity: `error`,
                            summary: this.translateService.instant(`action.error`),
                            detail: this.translateService.instant(`cart.unableToCreateNewData`),
                        });
                        throw new Error(`Niepowodzenie`);
                    });
            }

            const getMeResponse = (await this.apiService.users.usersControllerGetMe({
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            })) as any;

            this.customerInfos = getMeResponse?.data.customerInfos;

            const oldestCustomerInfo = this.customerInfos.reduce((prev: any, current: any) => {
                return prev.createdAt > current.createdAt ? prev : current;
            });

            this.selectedCustomerInfo = {
                label: oldestCustomerInfo.name,
                customerInfo: oldestCustomerInfo,
            };

            this.store.dispatch(new AppStateActions.GetUser());
        }

        this.displayCustomerInfoLayoutDialog = false;
        this.approvedCustomerInfo = true;
    }

    deduceCustomerInfoName() {
        if (
            !this.customerInfoLayoutFormGroup.get(`firstName`)?.value ||
            !this.customerInfoLayoutFormGroup.get(`lastName`)?.value
        ) {
            if (
                !this.selectedCustomerInfo?.customerInfo.firstName ||
                !this.selectedCustomerInfo?.customerInfo.lastName
            ) {
                return ``;
            }
            return `${this.selectedCustomerInfo?.customerInfo.firstName} ${this.selectedCustomerInfo?.customerInfo.lastName}`;
        }

        return `${this.customerInfoLayoutFormGroup.get(`firstName`)?.value} ${
            this.customerInfoLayoutFormGroup.get(`lastName`)?.value
        }`;
    }
    async clearShoppingListItems(){
        if (this.userData?.id) {
            await this.apiService.users.usersControllerClearShoppingList(
                this.userData.id,
                {
                    transportAuthorityId: this.selectedTransportAuthority.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                },
            );
        }
        this.newShoppingListItems.pop();
        this.calculateItemsPrice(this.newShoppingListItems);
        this.store.dispatch(new AppStateActions.GetUser());

    }
    async removeShoppingItemFromCart(item: ShoppingListItem) {
        const index = this.newShoppingListItems.findIndex((shoppingList: any) => {
            return shoppingList === item;
        });

        this.newShoppingListItems.splice(index, 1);

        // MAYBE CHECK
        const toUpdateShoppingList = this.shoppingListToDatabaseFormat(this.newShoppingListItems).map((listItem) => {
            const { ticket, duration, activeFrom, ticketZone, ...newItem } = listItem;
            return newItem;
        });

        this.getVisibleTicketCarriers(this.newShoppingListItems);

        if (this.userData?.id) {
            await this.apiService.users.usersControllerUpdateShoppingList(
                this.userData.id,
                {
                    transportAuthorityId: this.selectedTransportAuthority.id,
                    items: toUpdateShoppingList,
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                },
            );
        }

        this.calculateItemsPrice(this.newShoppingListItems);

        this.store.dispatch(new AppStateActions.GetUser());
    }

    getVisibleTicketCarriers(shoppingList: any) {
        const ticketCarrierIds = [
            ...new Set(
                shoppingList.map((item: any) => {
                    return item.ticketCarrierId;
                }),
            ),
        ];

        this.ticketCarriers = ticketCarrierIds.map((ticketCarrierId: any) => {
            return this.userData?.ticketCarriers?.find((carrier: any) => {
                return carrier.id === ticketCarrierId;
            });
        });
    }

    /* ------------------------------ click events ------------------------------ */
    choosePaymentMethod() {
        if (this.selectedTransportAuthority?.paymentMethods.length > 1) {
            this.displayPaymentMethodDialog = true;
        }
    }

    selectPaymentMethod(paymentMethod: any) {
        this.selectedPaymentMethod = paymentMethod;
        this.displayPaymentMethodDialog = false;
    }

    async navigateHome() {
        return this.router.navigate([`/${localStorage.getItem(`transport-authority`)}/bilety`]);
    }

    cartShow() {
        if (this.displayCartDialog) {
            this.store.dispatch(new AppStateActions.HideCart(true));
            return;
        }
        this.store.dispatch(new AppStateActions.HideCart(false));

        if (!this.userData) {
            return;
        }

        if (this.selectedTransportAuthority) {
            [this.selectedPaymentMethod] = this.selectedTransportAuthority.paymentMethods;
        }

        if (!this.selectedCustomerInfo && this.currentInfos.length === 0) {
            [this.selectedCustomerInfo] = this.mappedCustomerInfos;
            this.customerInfoLayoutFormGroup.patchValue(this.selectedCustomerInfo?.customerInfo);
        }
    }

    cartHide() {
        this.store.dispatch(new AppStateActions.HideCart(true));
    }

    async buyTicket() {
        const orderItems = this.prepareOrderItems();

        const {
            firstName,
            lastName,
            line1,
            line2,
            city,
            postalCode,
            phoneNumber,
            companyName,
            vatNumber,
            dateOfBirth
        } = this.customerInfoLayoutFormGroup.value;

        const { invoice } = this.invoiceForm.value;

        const orderInput: OrderInput = {
            orderItems,
            name: lastName ? `${firstName} ${lastName}` : firstName,
            address: line2 ? `${line1} ${line2}` : line1,
            paymentMethodId: this.selectedPaymentMethod.id,
            language: `PL`,
            city,
            zip: postalCode,
            country: `Poland`,
            phone: phoneNumber,
            deliveryMethod: this.supplyMethod?.id,
            pickupStop: this.selectedPickupPoint,
            companyName,
            vatNumber,
            invoice,
            dateOfBirth: dateOfBirth
        };

        if (this.selectedPaymentMethod?.paymentOperator.name === `Przelewy24`) {
            try {
                const buyTicketResponse = await this.apiService.payments.paymentsControllerBuyTickets(
                    this.selectedTransportAuthority.id,
                    orderInput,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    },
                ) as any;

                if (!buyTicketResponse) {
                    this.messageService.add({
                        severity: `error`,
                        summary: this.translateService.instant(`action.error`),
                        detail: this.translateService.instant(`cart.actionFailed`),
                    });
                    this.displayPaymentRedirectDialog = false;

                    setTimeout(() => {
                        this.buyButtonClicked = false;
                    }, 1000);
                    return;
                }
                if (buyTicketResponse){
                    if (this.userData?.guest === true){
                        // Log out.
                        const token = localStorage.getItem(`token`);

                        await this.refreshTokenService.clearUserTimeout();

                        await this.apiService.users.usersControllerLogout({
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            credentials: `include`,
                        });

                        localStorage.removeItem(`token`);

                    }
                }
                this.redirect(buyTicketResponse);

                return;
            } catch (err) {
                const isProductExceedingQuantity = err.status === 400;
                if (isProductExceedingQuantity) {
                    this.handleMissingProductQuantity(err.error);
                    this.displayPaymentRedirectDialog = false;
                    this.buyButtonClicked = false;
                } else {
                    console.error(err);
                }

                return;
            }
        }

        const buyTicketResponse = (await this.apiService.transportAuthorities.tpayControllerBuyTickets(
            this.selectedTransportAuthority.id,
            orderInput,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            },
        )) as any;

        if (!buyTicketResponse) {
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`cart.actionFailed`),
            });
            this.displayPaymentRedirectDialog = false;

            setTimeout(() => {
                this.buyButtonClicked = false;
            }, 1000);
            return;
        }

        if (buyTicketResponse.data.result === 1) {
            this.redirect(buyTicketResponse);
        }
    }

    private redirect({ data }: any): void {
        setTimeout(() => {
            this.displayPaymentRedirectDialog = false;

            if (data === `redirect` || data.url == null) {
                this.router.navigateByUrl(`/uzytkownik/profil/karty`);
            } else {
                window.location.href = data.url;
            }
        }, 1000);
    }

    private handleMissingProductQuantity({ items }: LackOfProductsError) {
        this.updateQuantitiesInCart(items);

        this.showNotificationAboutUpdatedQuantities(items);
    }

    private updateQuantitiesInCart(items: LackOfProductsError['items']) {
        items.forEach(({ productId, amount }) => {
            const cartItem = this.newShoppingListItems.find((shoppingListItem) => shoppingListItem.productId === productId)!;

            if (amount === 0) {
                this.removeShoppingItemFromCart(cartItem);
            } else {
                this.onCartModifiedCount(cartItem, amount);
            }
        });
    }

    private showNotificationAboutUpdatedQuantities(items: LackOfProductsError['items']) {
        const updatedProductIds = items.map(({ productId }) => productId);

        const updatedProducts = this.mapIdsToProductNamesString(updatedProductIds);
        const removedProductIds = items
            .filter(({ amount }) => amount === 0)
            .map(({ productId }) => productId);
        const removedProducts = this.mapIdsToProductNamesString(removedProductIds);

        [
            {
                severity: `warn`,
                detail: this.translateService.instant(`cart.quantityChanged`, { updatedProducts }),
            },
            {
                severity: `error`,
                detail: this.translateService.instant(`cart.productsRemoved`, { removedProducts }),
            },
        ].forEach(({ severity, detail }) => {
            this.messageService.add({
                severity,
                detail,
                summary: this.translateService.instant(`cart.quantityExceeded`),
                life: 15000,
            });
        });
    }

    private mapIdsToProductNamesString(ids: string[]) {
        return ids
            .map((productId) => {
                const { name } = this.availableProducts.find(({ id }) => productId === id)!;

                return name;
            })
            .join(`, `);
    }

    private prepareOrderItems() {
        const items = this.shoppingListToDatabaseFormat(this.newShoppingListItems)
            .map((item) => {
                if (!item.activeFromNow) {
                    item.activateAfterPayment = false;
                }

                const { ticket, activeFromNow, duration, ticketZone, activeFrom, type, product, ...strippedItem } = item;
                return strippedItem;
            });

        const { ticketCarrierId } = items.find(({ ticketId }) => ticketId != null) || items.find(({ productId }) => productId != null);

        return items.map(({ ...params }) => ({
            ...params,
            ticketCarrierId,
        }));
    }

    getFormattedDate(activationDate: any) {
        if (!activationDate) {
            return;
        }
        return fns.format(fns.parseISO(activationDate), `dd.MM.yyyy HH:mm`);
    }

    buyTicketRedirect() {
        this.buyButtonClicked = true;

        if (!this.selectedPaymentMethod) {
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`cart.missingPaymentProvider`),
            });
            setTimeout(() => {
                this.buyButtonClicked = false;
            }, 1000);
            return;
        }

        if (!this.customerInfoLayoutFormGroup.value.firstName || !this.customerInfoLayoutFormGroup.value.lastName) {
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`cart.fillInRequiredPersonalData`),
            });
            setTimeout(() => {
                this.buyButtonClicked = false;
            }, 1000);
            return;
        }

        if (this.isAnyProductInCart && !this.supplyMethod) {
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`cart.addDeliveryFirst`),
            });
            setTimeout(() => {
                this.buyButtonClicked = false;
            }, 1000);
            return;
        }

        const roundedCurrentDate = new Date(Math.floor(new Date().getTime() / this.ms) * this.ms);

        this.displayPaymentRedirectDialog = true;
        void this.buyTicket();
    }

    shoppingListFromDatabaseFormat(list: any[]) {
        return list.map((item: any) => {
            return {
                ...item,
                activatedAt: {
                    value: item.activatedAt,
                    feedbackClass: ``,
                },
            };
        });
    }

    shoppingListToDatabaseFormat(list: any[]) {
        return list.map((item: any) => {
            return {
                ...item,
                activatedAt: item.activatedAt?.value,
            };
        });
    }

    onHideConfirmationDialog(event: number) {
        const roundedCurrentDate = new Date(Math.floor(new Date().getTime() / this.ms) * this.ms);

        if (event === 1 || event === 2) {
            this.newShoppingListItems = this.newShoppingListItems.map((shoppingItem: any) => {
                if (fns.compareAsc(roundedCurrentDate, new Date(shoppingItem.activatedAt.value)) > 0) {
                    return {
                        ...shoppingItem,
                        activatedAt: {
                            value: shoppingItem.activatedAt.value,
                            feedbackClass: `text-red-500`,
                        },
                    };
                }
                return shoppingItem;
            });
        }
    }

    checkShoppingListCount() {
        let counter = 0;
        if (this.newShoppingListItems.length === 0) {
            return 0;
        }

        for (const item of this.newShoppingListItems) {
            counter += Number(item.count);
        }

        if (counter > 99) {
            return `99+`;
        }

        return counter;
    }

    resolveTicketZone(ticket: any) {
        return calculateTicketZoneAndArea(ticket);
    }

    resolveTicketDuration(ticket: any) {
        return calculateViewTicketDuration(ticket);
    }

    resolveTicketDiscount(ticket: any){
        return calculateViewTicketDiscount(ticket);
    }

    private calculateItemsPrice(items: ShoppingListItem[]): void {
        this.itemsPrice = _.sumBy(items, (o) =>
            o.count * (o.ticket || o.product)?.priceWithTax);
    }

    private get token(): string | null {
        return localStorage.getItem(`token`);
    }

    increaseFontSize() {
        this.changeFontSize(5);
    }

    decreaseFontSize() {
        this.changeFontSize(-5);
    }

    private changeFontSize(value: number) {
        this.fontSize = this.restrict(this.fontSize + value, 70, 140);
        this.html.style.fontSize = `${this.fontSize}%`;
    }

    private restrict(val: number, min: number, max: number) {
        return Math.min(Math.max(val, min), max);
    }

    updateVatNumberValidators(invoiceIncluded: boolean): void {
        const { vatNumber } = this.customerInfoLayoutFormGroup.controls;

        // * According to @Sebastian Wachowicz we can file invoices without NIP.
        // if (invoiceIncluded) {
        //     vatNumber.setValidators([Validators.required, nipValidator]);
        // } else {
        //     vatNumber.clearValidators();
        // }

        vatNumber.updateValueAndValidity();
    }
}
